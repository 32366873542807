import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/index.scss";
import "@/assets/css/mobile.scss";
import 'animate.css';

Vue.config.productionTip = false
Vue.use(ElementUI);

//在main.js添加下面代码

//监听元素是否进入视图
Vue.directive('animate', {
  bind(el, binding) {
    const options = binding.value || {};


    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        let hasEntered = entry.target.classList.contains(options.appearClass); // 用于追踪元素是否已经进入视图
        if (entry.isIntersecting && !hasEntered) {
          // 元素进入视图，添加CSS类触发动画
          el.classList.add(options.appearClass || 'slide-in');
          hasEntered = true; // 更新状态
        }
        // else if (!entry.isIntersecting && hasEntered) {
        //   // 元素离开视图，移除CSS类
        //   el.classList.remove(options.appearClass || 'slide-in');
        //   hasEntered = false; // 更新状态
        // }
      });
    }, options.intersectionOptions || {});

    observer.observe(el);
  },
});

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 视频播放器
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
import './assets/js/flexible.js'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
