<template>
  <div class="padding-tb-xl carousel">
    <carousel ref="carousel" arrow="never" type="card" :autoplay="false" height="600px" v-bind="$attrs">
      <carousel-item v-for="(item, index) in imageList" :key="item.url">
        <el-image :src="item.url" class="carousel-image"></el-image>
      </carousel-item>
    </carousel>
    <button @click="prev" class="el-carousel__arrow el-carousel__arrow--left">
      <i class="el-icon-arrow-left"></i>
    </button>
    <button @click="next" class="el-carousel__arrow el-carousel__arrow--right">
      <i class="el-icon-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import carousel from "./src/main.vue";
import carouselItem from "./src/item.vue";
import _ from "lodash";
export default {
  components: {
    carousel,
    carouselItem
  },
  props: {
    imageList: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  methods: {
    prev: _.throttle(function () {
      this.carouselPrev()
    }, 500),
    next: _.throttle(function () {
      this.carouselNext()
    }, 500),
    carouselPrev() {
      this.$refs.carousel.prev();
    },
    carouselNext() {
      this.$refs.carousel.next();
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  position: relative;
}
</style>