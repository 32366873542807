<template>
  <div class="content footer">
    <!-- <div v-if="show" class="top flex-center flex-wrap">
            <div style="width: 800px;margin: 0 auto;">
                <div class="flex-center text-top text-sl margin-bottom justify-around" style="width: 100%;">
                    <div class="text-bold">渠道合作</div>
                </div>
                <div class="flex-center justify-around">
                    <el-image class="image" :src="image1"></el-image>
                </div>
            </div>
        </div> -->
    <DialogContent
      v-animate="{ appearClass: 'animate__fadeInLeft' }"
      class="animate__animated"
      :isMobile="isMobile"
      @openDialog="openDialog"
      v-if="$route.name == 'index'"
    ></DialogContent>
    <div
      v-if="show"
      v-animate="{ appearClass: 'animate__fadeInRight' }"
      class="animate__animated middle text-white flex-center justify-center"
    >
      <div class="text-center">
        <div>
          <span class="padding-right-sm middle-text"
            >大模型AI员工<span class="right-text">自动聊单</span></span
          >
          <br v-if="isMobile" />
          <span>让私域没有难聊的生意</span>
        </div>
        <el-button @click="openDialog" class="btn margin-top-xl">
          <i class="icon-detail"></i> 联系我们
        </el-button>
        <!-- <el-button @click="openDialog" class="btn">免费试用</el-button> -->
      </div>
    </div>
    <div class="bottom flex-center justify-center text-df padding-sm">
      <div class="flex-center bottom-content justify-center">
        <div class="flex-sub text-lg justify-center text-white">
          <div class="icon-logo"></div>
          <!-- <div class="margin-top-sm">全球数智人力资源中心</div> -->
          <div class="margin-top margin-bottom-sm text-xsl">025-86520973</div>
          <div class="address text-df">南京市雨花台区软件谷科创城A1北17楼</div>
          <div class="margin-top-sm text-df">
            市场/战略合作：anni@rightbot.cn
          </div>
        </div>
        <div class="text-white flex-sub">
          <div class="text-xl text-center">关注信人智能，了解更多产品资讯</div>
          <div class="text-right margin-top">
            <el-image class="qrcode margin-right-xl" :src="qrcode"></el-image>
            <el-image class="qrcode margin-left-sm" :src="qrcode1"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="text-center text-white">
        <div>
          Copyright © 2020-2024 南京信人智能科技有限公司<a
            class="margin-lr-xs"
            href="https://beian.miit.gov.cn/"
            target="_black"
            >苏ICP备2023024946号-2</a
          >
          <!-- <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502005160">苏公网安备 42018502005160号</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogContent from "./dialogContent.vue";
export default {
  props: { isMobile: { type: Boolean, default: false } },
  components: { DialogContent },
  data() {
    return {
      activeName: "first",
      image:
        "https://oss.isuiyou.cn/af5975d03ff9adfffc0e5036b2c52094fc535ac2db13950313f912981d3614fa.png",
      image1:
        "https://oss.isuiyou.cn/scrm_console/knowledge/qa/89c4348e-d161-4c44-9c80-6fe5b63e4613/f7807b2df73af21f9f7a8fa59a3365c.png",
      qrcode:
        "https://oss.isuiyou.cn/8be4f1172ac73bf6c289a7039abfdd21bae4bf14f7d2f8cf5554cedbb7cfda22.png",
      qrcode1:
        "https://oss.isuiyou.cn/8b7a74354441a58779ec83acb858e1c905da4a4cc3193d88656c97e42eac6d38.png",
      show: true,
    };
  },
  methods: {
    openDialog() {
      this.$emit("openDialog");
    },
    createAnimate(currentPosition) {
      // this.showMainBottomContainer = currentPosition > this.$refs.mainBottomContainer.offsetTop
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        this.show = to.path == "/";
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  .top {
    background-color: #f5f9ff;
    height: 460px;

    .top-tabs {
      width: 320px;
    }
  }

  .middle {
    height: 560px;
    color: #000;
    font-size: 56px;
    font-weight: 900;
    background: #ecf4fe;

    .btn {
      height: 60px;
      width: 200px;
      color: #fff;
      background: #000000;
      border-radius: 390px;
      font-size: 20px;
    }

    .right-text {
      position: relative;
      word-spacing: 5px;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      color: transparent;
      background: linear-gradient(
        90deg,
        #8c28f1 5%,
        #cc28f1 31%,
        #cc28f1 53%,
        #c11f1f 77%
      );
      background-clip: text;
      background-position: 50% 0;
    }
  }

  .bottom {
    height: 367px;
    background-color: #1d1d1f;

    .bottom-content {
      width: 1080px;
    }
  }

  .copyright {
    height: 62px;
    line-height: 62px;
    font-size: 16px;
    background: #1d1d1f;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .image {
    width: 265px;
    height: 265px;
  }

  .qrcode {
    width: 187px;
    height: 187px;
    border-radius: 8px;
    vertical-align: top;
  }

  .text-top {
    color: #6f7c95;

    div::after {
      margin: 10px auto;
      content: "";
      width: 32px;
      height: 2px;
      background: #6f7c95;
      display: block;
    }
  }

  .icon-logo {
    width: 274px;
    height: 71px;
    display: inline-block;
    vertical-align: middle;
    background: url(https://oss.isuiyou.cn/5a32e6e4bb89bfe40819e56e257e160c10f9b73e7444188ec48f7658c53e0d82.png)
      center center no-repeat;
    background-size: contain;
  }
}
</style>
