import request from '@/assets/js/http.js'
// 保存潜在客户信息
export function websiteSave(data) {
    return request({
        url: '/customer/appOfficialWebsite',
        method: 'post',
        data
    })
}
// 公众号信息分页查询
export function getOfficialAccountPage(params) {
	return request({
		url: '/customer/officialAccountInfo/page',
		method: 'get',
		params
	});
}
