<template>
  <div>
    <div class="honor text-center text-white padding-top-xl padding-bottom">
      <div class="honor-text-xl">公司荣誉</div>
      <div class="text-20">每一份荣誉，都是我们创新的印记。</div>
      <div class="el-tabs-after margin-bottom">
        <el-tabs class="margin-top-lg" v-model="activeName">
          <el-tab-pane label="荣誉证书" name="honorList"></el-tab-pane>
          <el-tab-pane label="专利证书" name="copyrightList"></el-tab-pane>
        </el-tabs>
      </div>
      <myCarousel :image-list="activeName == 'honorList' ? honorList : copyrightList"></myCarousel>
    </div>
    <div class="margin-top-xl dynamic" v-if="totalPage > 0">
      <div class="honor-text-xl padding-tb-xl text-center">公司动态</div>
      <div style="position: relative;">
        <el-carousel ref="carousel" :interval="5000" height="700px" arrow="never" :autoplay="false">
          <el-carousel-item v-for="(item, index) in totalPage" :key="item">
            <div class="flex flex-wrap" style="gap:26px;">
              <div class="bg-white dynamic-item radius-8" v-for="(i, v) in dataList.slice(index * 8, (index + 1) * 8)"
                :key="i">
                <el-image @click="toDetail(i.url)" :src="i.pictureUrl" class="image pointer"></el-image>
                <div class="padding-sm">
                  <div @click="toDetail(i.url)" class="text-sm title-view text-cut2 pointer margin-bottom-sm">
                    {{ i.title }}
                  </div>
                  <div class="flex-center justify-between">
                    <div class="flex-center bg-orange light radius" style="padding: 3px 10px;">
                      <div class="icon-calendar"></div>
                      <div class="text-orange">{{ formatDate(i.showDate, 'MM/dd') }}</div>
                    </div>
                    <el-link :underline="false" @click="toDetail(i.url)" type="primary">详情 ></el-link>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <button @click="leftHandler" v-if="totalPage > 1" class="el-carousel__arrow el-carousel__arrow--left">
          <i class="el-icon-arrow-left"></i>
        </button>
        <button @click="rightHandler" v-if="totalPage > 1" class="el-carousel__arrow el-carousel__arrow--right">
          <i class="el-icon-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import myCarousel from "./carousel/index.vue";
import { getOfficialAccountPage } from "@/api/index"
export default {
  components: {
    myCarousel
  },
  data() {
    return {
      activeName: "honorList",
      honorList: [{ url: "https://oss.real-bot.cn/web/website/certificate/1-101.jpg" },
      { url: "https://oss.real-bot.cn/web/website/certificate/2-201.png" },
      { url: "https://oss.real-bot.cn/web/website/certificate/3-301.jpg" },
      { url: "https://oss.real-bot.cn/web/website/certificate/4-401.jpg" },
      { url: "https://oss.real-bot.cn/web/website/certificate/5-501.png" },
      { url: "https://oss.real-bot.cn/web/website/certificate/6-601.png" },
      { url: "https://oss.real-bot.cn/web/website/certificate/7-701.png" },
      { url: "https://oss.real-bot.cn/web/website/certificate/8-801.png" },
      { url: "https://oss.real-bot.cn/web/website/certificate/9-302.jpg" },
      { url: "https://oss.real-bot.cn/web/website/certificate/10-202.png" },
      ],
      copyrightList: [
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20231218.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20231219.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20231220.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20231227.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20240105.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20240326.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20240513.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241016.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241017.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241018.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241021.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241022.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241023.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241024.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241025.jpg" },
        { url: "https://oss.real-bot.cn/web/website/certificate/RZ_20241031.jpg" }
      ],
      dataList: [],
      totalPage: 0,
    }
  },
  methods: {
    leftHandler() {
      this.$refs.carousel.prev();

    },
    rightHandler() {
      this.$refs.carousel.next();
    },
    getList() {
      getOfficialAccountPage({ current: 1, size: 40, status: 1 }).then(res => {
        this.totalPage = Math.ceil(res.data.data.records.length / 8);
        this.dataList = res.data.data.records;
      })
      // this.dataList = Array.from({ length: 40 }).map((v, i) => {
      //   return { url: `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/320/160`, title: i }
      // })
    },
    toDetail(url) {
      window.open(url);
    },
    formatDate(date, fmt) {
      date = this.convertDate(date);
      const o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt)) { // 年份
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
      }
      return fmt;
    },
    convertDate(date) {
      if (typeof date === 'string') {
        return new Date(date);
      }
      return date;
    }
  },
  mounted() {
    this.getList();
  },
}
</script>

<style lang="scss" scoped>
.honor-text-xl {
  font-size: 58px;
  margin-bottom: 20px;
  font-weight: bold;
}

.honor ::v-deep {
  background-color: #1D1D1F;

  .el-tabs__nav-wrap {
    &::after {
      display: none;

    }
  }

  .el-tabs-after {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 12px;
      background-color: rgba($color: #fff, $alpha: 0.15);
    }

    .el-tabs {
      display: inline-flex;

      .el-tabs__active-bar {
        background-color: #20DBC8;
      }

      .el-tabs__nav {
        .el-tabs__item {
          margin-bottom: 10px;
          font-size: 24px;
          color: #fff;

          &.is-active {
            color: #20DBC8;
          }
        }
      }
    }
  }

  .carousel {
    max-width: 1360px;
    margin: 0 auto;

    .carousel-image {
      width: 414px;
      height: 560px;
    }

    .el-carousel__arrow--left {
      left: -60px;
    }

    .el-carousel__arrow--right,
    .el-notification.right {
      right: -60px;
    }
  }

  .el-carousel__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: linear-gradient(180deg, #F6F7F9 0%, rgba(255, 255, 255, 0.68) 100%);
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
  }

  .el-carousel__indicators {
    display: none;
  }

  .el-carousel__arrow {
    background-color: #fff;

    .el-icon-arrow-left,
    .el-icon-arrow-right {
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .el-carousel__item {
    width: 453px;
    height: 596px;

    h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }
  }
}

.title-view {
  height: 40px;
}

.dynamic ::v-deep {
  max-width: 1360px;
  margin: 0 auto;

  .dynamic-item {
    width: 320px;
    overflow: hidden;
  }

  .image {
    width: 320px;
    height: 136px;
  }

  .icon-calendar {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background: url("../assets/calendar.png") center center no-repeat;
    background-size: contain;
  }

  .el-carousel__arrow--left {
    left: -100px;
  }

  .el-carousel__arrow--right,
  .el-notification.right {
    right: -100px;
  }

  .el-carousel__arrow {
    background-color: transparent;
    border: 2px solid #D8D8D8;

    .el-icon-arrow-left,
    .el-icon-arrow-right {
      color: #b1afaf;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .el-carousel__indicator {

    .el-carousel__button {
      height: 6px;
      width: 50px;
      border-radius: 1px;
      background: #D8D8D8;
    }

    &.is-active .el-carousel__button {
      background-color: #0066FE;
    }
  }
}
</style>